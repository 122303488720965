import React, { useState } from 'react';
import { pageTitle } from '../PageTitle';
import { Link } from 'react-router-dom';
import { Background } from 'react-parallax';
import ServiceModal from './ServiceModal'


const Services = () => {
  pageTitle('Services');
  const servicesData = [
    {
      id: 1,
      title: 'Endpoint Monitoring',
      description: 'Consistency in posting content fosters a sense of reliability among followers.',
      icon: 'icon-04',
      href: '/services/endpointmonitoring',
      data_aos_delay:'00ms',
  },
  {
      id: 2,
      title: 'Advanced AntiVirus',
      description: 'Consistency in posting content fosters a sense of reliability among followers.',
      icon: 'icon-08',
      href: '/services/advancedantivirus',
      data_aos_delay:'200ms',
  },
  {
      id: 3,
      title: 'Cloud Backup',
      description: 'Consistency in posting content fosters a sense of reliability among followers.',
      icon: 'icon-12',
      href: '/services/cloudbackup',
      data_aos_delay:'400ms',
  },
  {
      id: 4,
      title: 'License Management',
      description: 'Consistency in posting content fosters a sense of reliability among followers.',
      icon: 'icon-04',
      href: '/services/licensemanagement',
      data_aos_delay:'00ms',
  },
  {
      id: 5,
      title: 'AI/Machine Learning/LLMs',
      description: 'Consistency in posting content fosters a sense of reliability among followers.',
      icon: 'icon-08',
      href: '/services/aiml',
      data_aos_delay:'200ms',
  },
  {
      id: 6,
      title: 'IT Staffing',
      description: 'Consistency in posting content fosters a sense of reliability among followers.',
      icon: 'icon-12',
      href: '/services/itstaffing',
      data_aos_delay:'400ms',
  },
  {
      id: 7,
      title: 'Network Monitoring',
      description: 'Consistency in posting content fosters a sense of reliability among followers.',
      icon: 'icon-12',
      href: '/services/networkmonitoring',
      data_aos_delay:'400ms',
  },
  {
      id: 8,
      title: 'Cybersecurity Document Generation and Assessments',
      description: 'Consistency in posting content fosters a sense of reliability among followers.',
      icon: 'icon-12',
      href: '/services/cybersecuritydocs',
      data_aos_delay:'400ms',
  },
  {
      id: 9,
      title: 'IT Projects',
      description: 'Consistency in posting content fosters a sense of reliability among followers.',
      icon: 'icon-12',
      href: '/services/itprojects',
      data_aos_delay:'400ms',
  },
  ];

  const serviceData = {
    endpointmonitoring: {
      title: 'Endpoint Monitoring',
      description: 'Our Endpoint Monitoring service ensures that your devices, whether on-premises or remote, are continuously monitored for security threats and performance issues. We provide real-time alerts, detailed reports, and proactive maintenance to ensure that your business operations remain uninterrupted and secure.',
      keyBenefits: [
        '24/7 monitoring of all endpoints',
        'Proactive detection and response to threats',
        'Real-time reporting and alerts',
        'Automated patch management',
        'Improved device performance and longevity',
      ],
    },
    advancedantivirus: {
      title: 'Advanced AntiVirus',
      description: 'Our Advanced AntiVirus solution offers robust protection against the latest malware, ransomware, and phishing attacks. By integrating AI-driven threat detection, our service ensures that your data remains safe from evolving cyber threats.',
      keyBenefits: [
        'AI-powered threat detection',
        'Real-time scanning and removal',
        'Protection against ransomware and phishing',
        'Automatic updates to stay ahead of threats',
        'Comprehensive reporting and alerting',
      ],
    },
    cloudbackup: {
      title: 'Cloud Backup',
      description: 'Ensure your business data is safe and recoverable with our Cloud Backup service. We provide secure, automated backups to the cloud, with easy access to restore files and data whenever needed.',
      keyBenefits: [
        'Automated daily backups',
        'Data encryption for security',
        'Quick and easy data recovery',
        'Scalable storage solutions',
        'Compliance with data protection regulations',
      ],
    },
    licensemanagement: {
      title: 'License Management',
      description: 'Our License Management service helps you keep track of your software licenses, ensuring compliance and avoiding costly penalties. We manage renewals, monitor usage, and optimize your license inventory to reduce unnecessary costs.',
      keyBenefits: [
        'Automated license tracking and renewals',
        'Compliance assurance',
        'Cost optimization through license usage analysis',
        'Detailed reporting on software assets',
        'Minimized risk of license violations',
      ],
    },
    aiml: {
      title: 'AI/Machine Learning/LLMs',
      description: 'Our AI and Machine Learning services empower your business with cutting-edge technology. From predictive analytics to language models, we provide customized AI solutions that help you gain insights, automate processes, and drive innovation.',
      keyBenefits: [
        'Custom AI model development',
        'Predictive analytics and forecasting',
        'Natural Language Processing (NLP) with LLMs',
        'AI-driven automation of business processes',
        'Integration with existing systems and data sources',
      ],
    },
    itstaffing: {
      title: 'IT Staffing',
      description: 'Our IT Staffing services provide your business with the skilled professionals you need, whether for short-term projects or long-term positions. We ensure that you have access to top talent with the expertise required to drive your IT initiatives forward.',
      keyBenefits: [
        'Access to a vast pool of IT talent',
        'Flexible staffing solutions for projects and full-time roles',
        'Streamlined recruitment and onboarding process',
        'Ongoing support and training for placed staff',
        'Dedicated account management',
      ],
    },
    networkmonitoring: {
      title: 'Network Monitoring',
      description: 'Our Network Monitoring service provides real-time visibility into your network’s performance and security. We help you identify and resolve issues before they impact your business, ensuring optimal network uptime and reliability.',
      keyBenefits: [
        '24/7 network monitoring',
        'Proactive alerting and issue resolution',
        'Bandwidth usage and performance analysis',
        'Security monitoring to detect intrusions and anomalies',
        'Detailed reporting and dashboards',
      ],
    },
    cybersecuritydocs: {
      title: 'Cybersecurity Document Generation and Assessments',
      description: 'We provide comprehensive cybersecurity document generation and assessments to help your business maintain compliance and stay protected. Our services include risk assessments, policy creation, and compliance audits tailored to your specific industry needs.',
      keyBenefits: [
        'Customized cybersecurity policy creation',
        'Risk assessments and vulnerability scanning',
        'Compliance audits for industry regulations',
        'Continuous improvement recommendations',
        'Ongoing support and updates',
      ],
    },
    itprojects: {
      title: 'IT Projects',
      description: 'We manage and execute IT projects of any scale, ensuring that your initiatives are completed on time, within budget, and to your exact specifications. From infrastructure upgrades to software development, we bring the expertise needed to drive your projects to success.',
      keyBenefits: [
        'Project planning and management',
        'Infrastructure upgrades and migrations',
        'Custom software development',
        'Cloud solutions implementation',
        'Post-project support and maintenance',
      ],
    },
  }

  const [selectedService, setSelectedService] = useState(null);

  const openModal = (item) => {
    // Find the service data based on the title or id
    const service = serviceData[item.href.split('/').pop()]; // Extracts the last part of the URL (like "endpointmonitoring")
    setSelectedService(service);
  };
  

const closeModal = () => {
    setSelectedService(null);
};

    return (
        <>
        <div className="service__page p_relative see__pad" style={{backgroundColor:'black'}}>
            <h1 style={{color: 'white' , textAlign:'center', paddingBottom:60}}>Services</h1>
            <div className="auto-container">
                <div className="row">
                    {servicesData.map((item, id)=>(
                        <div className="col-lg-4 col-md-6 col-sm-12 wow fadeInUp animated" data-wow-delay={item.data_aos_delay} data-wow-duration="1500ms" key={id} >
                            <div className="service__block">
                                <div className="service__icon">
                                    <i className={item.icon}></i>
                                    <div className="service__icon__two"></div>
                                </div>
                                <div className="service__text">
                                    <h4>{item.title}</h4>
                                    <p>{item.description}</p>
                                </div>
                                
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <ServiceModal
        isOpen={selectedService !== null}
        onClose={closeModal}
        service={selectedService}
      />
        </div>
        </>
    )
};

export default Services