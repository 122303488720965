import React from 'react';
import shape4 from "../../assets/images/resource/choose-04.png";
import shape5 from "../../assets/images/shapes/shape-11.png";
import shape6 from "../../assets/images/shapes/shape-12.png";
import shape7 from "../../assets/images/Target.jpg";


const Choose = () => {
    return (
        <section className="choose___two see__pad p_relative">
        <div className="pattern-layer">
            <div className=" pattern-1 p_absolute" data-parallax='{"x": -75}' style={{ backgroundImage: `url(${shape4})` }}></div>
        </div>
        <div className="auto-container">
            <div className="row">
                <div className="col-lg-5 col-md-16">
                    <div className="choose_img__block">
                        <div className="title__data">
                            <div className="sub__title">
                                <h4>Why choose us</h4>
                            </div>
                            <div className="title">
                                <h2>Our Approach</h2>
                            </div>
                        </div>
                        <div className="choose_img__two p_relative">
                            <div className="pattern-layer">
                                <div className=" pattern-3 p_absolute" data-parallax='{"y": 10}' style={{ backgroundImage: `url(${shape5})` }}></div>
                            </div>
                            <div className="pattern-layer">
                                <div className=" pattern-4 p_absolute" data-parallax='{"y": -10}' style={{ backgroundImage: `url(${shape6})` }}></div>
                            </div>
                            <figure className="wow zoomIn animated animated" data-wow-delay="100ms" data-wow-duration="1500ms">
                                <img src={shape7} alt="" style={{height: '50vh', width: 'auto'}}/>
                            </figure>
                        </div>
                    </div>
                </div>
                <div className="col-lg-5 col-md-16">
                    <div className="choose__text__block">
                        <h2>Your IT Partner, Committed to Your Success</h2>
                        <div className="normal__text">
                            <p>At Just Do I.T., we go beyond traditional IT services by providing tailored solutions that meet the unique needs of small and medium-sized businesses. What sets us apart is our unwavering commitment to security, innovation, and customer-centric service. As a service-connected disabled veteran-owned company, we bring the discipline, dedication, and strategic approach that only military service can instill.</p>
                        </div>
                        <div className="customaize__solution">
                            <div className="customaize__block">
                                <h5>Security Built In:</h5>
                                <p>We integrate cybersecurity into every aspect of your business, ensuring robust protection without disrupting your operations. Your business's safety is our top priority.</p>
                            </div>
                            <div className="customaize__block">
                                <h5>Tailored Solutions:</h5>
                                <p>We understand that no two businesses are the same. Our team works closely with you to design and implement IT solutions that align perfectly with your specific needs and goals.</p>
                            </div>
                            <div className="customaize__block">
                                <h5>Veteran-Owned Excellence:</h5>
                                <p>Our military background provides us with a unique perspective on discipline, leadership, and reliability. We bring this commitment to every project, ensuring you receive the highest quality service.</p>
                            </div>
                            <div className="customaize__block">
                                <h5>Customer-Centric Approach:</h5>
                                <p>We don't just offer services; we build partnerships. Our goal is to cultivate long-term relationships by delivering exceptional value and responsive support that adapts as your business grows.</p>
                            </div>
                            <div className="customaize__block">
                                <h5>Innovation-Driven:</h5>
                                <p>We stay ahead of the curve by continually exploring and implementing the latest technologies. Our solutions are not just about solving today’s problems but also about preparing your business for tomorrow's challenges.</p>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </section>
    );
};

export default Choose;