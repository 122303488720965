import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import HomeTwo from './Pages/HomeTwo/HomeTwo';
import About from './Pages/About/About';
import ScrollTop from './Pages/ScrollTop/ScrollTop';
import Services from './Pages/Services/Services';
import Projects from './Pages/Projects/Projects';
import ProjectsDetails from './Pages/Projects/ProjectsDetails';
import Pricing from './Pages/Pricing/Pricing';
import Team from './Pages/Team/Team';
import Testimonial from './Pages/Testimonial/Testimonial';
import Error from './Pages/Error/Error';
import Blog from './Pages/Blog/Blog';
import BlogDetails from './Pages/Blog/BlogDetails';
import Contact from './Pages/Contact/Contact';
import WOW from 'wowjs';
import ServiceDetail from './Pages/Services/ServicesDetails'; // Import the dynamic service detail component

function App() {

  useEffect(() => {
    new WOW.WOW({
      mobile: false
    }).init();
  }, []);

  return (
    <>
      <Router>
        <Routes>
          <Route path="/" element={<HomeTwo />} />
          <Route path="/about" element={<About />} />
          <Route path="/services" element={<Services />} />
          <Route path="/projects" element={<Projects />} />
          <Route path="/projects-details" element={<ProjectsDetails />} />
          <Route path="/pricing" element={<Pricing />} />
          <Route path="/team" element={<Team />} />
          <Route path="/testimonial" element={<Testimonial />} />
          <Route path="/error" element={<Error />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/blog-details" element={<BlogDetails />} />
          <Route path="/contact" element={<Contact />} />
          
          {/* Dynamic route for all service details */}
          <Route path="/services/:serviceId" element={<ServiceDetail />} />
        </Routes>
      </Router>
      <ScrollTop />
    </>
  );
}

export default App;
