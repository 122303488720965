import React, { useEffect, useRef, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import HeaderTwo from '../Header/HeaderTwo'; // Importing HeaderTwo component
import BannerBg from "../../assets/images/shapes/shape-07.png";
import BannerBg2 from '../../assets/images/shapes/shape-08.png';
import AboutUs from '../../assets/images/SDVOSB-logo.jpg';
// other imports...

const About = () => {
    const location = useLocation(); // Get current location
    const uxUiDesignRef = useRef(null);
    const webDesignRef = useRef(null);
    const developmentRef = useRef(null);
  
    const [isVisible, setIsVisible] = useState(false);
    const [selectedTenet, setSelectedTenet] = useState(null);
    const contentRefs = useRef([]);

    const tenets = [
        {
            title: 'Customer-Centric Commitment',
            description: 'We prioritize our customers\' needs, ensuring every interaction and solution revolves around delivering the best possible experience.'
        },
        {
            title: 'Tailored Solutions for Every Client',
            description: 'We recognize that each client is unique, which is why we customize our IT products and services to perfectly fit their specific requirements.'
        },
        {
            title: 'Exceeding Expectations',
            description: 'Our goal is not just to meet expectations but to go above and beyond, delivering results that surprise and delight our clients.'
        },
        {
            title: 'Partnership and Collaboration',
            description: 'We view our clients as partners, working closely together to achieve shared success through open communication and collaboration.'
        },
        {
            title: 'Responsive and Agile Service',
            description: 'We pride ourselves on being responsive and agile, quickly adapting to changes and addressing any issues as they arise.'
        },
        {
            title: 'Continuous Learning from Feedback',
            description: 'Feedback is crucial to our growth. We continuously learn and evolve based on the insights we receive from our clients.'
        },
        {
            title: 'Building Trust Through Transparency',
            description: 'Trust is the foundation of our relationships. We build it by being transparent in our communication and operations.'
        },
        {
            title: 'Innovation Driven by Client Needs',
            description: 'Innovation is at the heart of what we do. We develop new solutions and approaches driven by the evolving needs of our clients.'
        },
        {
            title: 'Commitment to Quality and Excellence',
            description: 'We are dedicated to maintaining the highest standards of quality and excellence in all aspects of our work.'
        },
    ];

    const handleSelection = (index) => {
        setSelectedTenet(selectedTenet === index ? null : index);
    };

    useEffect(() => {
        if (selectedTenet !== null) {
            const height = contentRefs.current[selectedTenet].scrollHeight;
            contentRefs.current[selectedTenet].style.maxHeight = `${height}px`;
        }
    }, [selectedTenet]);

    useEffect(() => {
        const handleScroll = () => {
            const elements = [uxUiDesignRef, webDesignRef, developmentRef];
  
            elements.forEach((ref) => {
                if (ref.current) {
                    const elementTop = ref.current.getBoundingClientRect().top;
                    const windowHeight = window.innerHeight;
  
                    if (elementTop < windowHeight - 50) {
                        setIsVisible(true);
                    }
                }
            });
        };
  
        window.addEventListener('scroll', handleScroll);
  
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
  
    useEffect(() => {
        if (isVisible) {
            const elements = [uxUiDesignRef, webDesignRef, developmentRef];
  
            elements.forEach((ref) => {
                if (ref.current) {
                    const percent = ref.current.getAttribute('data-percent');
                    ref.current.style.width = percent;
                    ref.current.classList.add('counted');
                }
            });
        }
    }, [isVisible]);

    useEffect(() => {
        const text_2 = document.querySelector(".text_2");
        if (text_2) {
            text_2.innerHTML = text_2.innerText
                .split("")
                .map(
                (char, i) => `<span style="transform:rotate(${i * 10.3}deg)">${char}</span>`
                )
                .join("");
        }
    }, []);

    return (
        <>
            {location.pathname.includes('/about') && <HeaderTwo />}
            <section className="about___two p_relative see__pad" style={{background:'#003366', paddingTop:'20vh'}}>
                <div className="pattern-layer">
                    <div className=" pattern-1 p_absolute" data-parallax='{"x": -100}' style={{ backgroundImage: `url(${BannerBg})` }}></div>
                </div>
                <div className="auto-container">
                    <div className="row">
                        <div className="col-lg-5 col-md-12">
                            <div className="about__two__img__block p_relative" style={{marginBottom:'10vh', width:'20vw', height:'20vw'}}>
                                <figure >
                                    <img src={AboutUs} alt="" className="about__two__img"/>
                                </figure>
                                <div className="about__two__circle ">
                                    <div className="pattern-layer">
                                        <div className=" pattern-2 p_absolute" data-parallax='{"y": 30}' style={{ backgroundImage: `url(${BannerBg2})` }}></div>
                                    </div>
                                    <div className="circle-main">
                                        <div className="circle">
                                            <div className="round-text">
                                                <div className="text_1">
                                                    <p className="text_2"></p>
                                                </div>
                                            </div>                        
                                        </div>
                                    </div>
                                </div>
                                <div className="about__text__two">
                                    {/* Additional content can go here */}
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-12"></div>
                        <div className="col-lg-5 col-md-12">
                            <div className="about__text__block">
                                <div className="sub__title">
                                    <h4>About Company</h4>
                                </div>
                                <div className="title two">
                                    <h2>Veteran-owned, mission-driven—serving our partners with <span>integrity</span> and <span>commitment</span>.</h2>
                                </div>
                                <div className="texts">
                                    <p>At the core of our business, we believe in a customer-centric approach, where every solution is tailored to meet the unique needs of our clients. We strive to exceed expectations by fostering true partnerships built on collaboration and trust. Our responsive and agile service ensures that we are always there when our clients need us, while our commitment to continuous learning allows us to innovate and adapt in response to feedback. With a steadfast focus on quality and excellence, we deliver solutions that not only meet but surpass our clients' goals.</p>
                                    <br></br>
                                    <h3 style={{textAlign:'center', paddingTop: '10vh', textDecoration:'underline'}}>Business Tenets</h3>
                                    <p style={{textAlign:'center', fontSize:'small', paddingBottom: '30px', color:'#FFD700'}}>(Click the tenet for more information.)</p>
                                    <div style={{ width: '100%', maxWidth: '600px', margin: '0 auto' }}>
                                        <ul style={{ listStyleType: 'none', padding: 0 }}>
                                            {tenets.map((tenet, index) => (
                                                <li key={index} style={{ marginBottom: '10px' }}>
                                                    <button 
                                                        onClick={() => handleSelection(index)} 
                                                        style={{
                                                            width: '100%',
                                                            textAlign: 'left',
                                                            padding: '10px',
                                                            color: 'white',
                                                            border: 'none',
                                                            borderRadius: '5px',
                                                            cursor: 'pointer'
                                                        }}
                                                    >
                                                        {tenet.title}
                                                    </button>
                                                    <div
                                                        ref={el => contentRefs.current[index] = el}
                                                        style={{
                                                            maxHeight: selectedTenet === index ? `${contentRefs.current[index]?.scrollHeight}px` : '0',
                                                            overflow: 'hidden',
                                                            transition: 'max-height 0.5s ease',
                                                            marginTop: '5px',
                                                            padding: selectedTenet === index ? '10px' : '0',
                                                            backgroundColor: '#2C3E50',
                                                            borderRadius: '5px',
                                                            color: '#7F8C8D'
                                                        }}
                                                    >
                                                        <p>{tenet.description}</p>
                                                    </div>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                                <div className="btn-box">
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default About;
