import React from 'react';
import { pageTitle } from '../PageTitle';
import HeaderOne from '../Header/HeaderTwo';
import FooterOne from '../Footer/FooterThree';
import BreadCrumb  from '../BreadCrumb';
import { Link } from 'react-router-dom';
import contact from "../../assets/images/contactus.jpg";
import map from "../../assets/images/resource/map.jpg";
import { DiBlackberry } from 'react-icons/di';
import contactEmail from './contact.php'


const Contact = () => {
    const handleSubmitOne = (event) => {
      event.preventDefault(); // Prevent default form submission behavior
  
      // Collect form data
      const formData = new FormData(event.target);
      const name = formData.get('name');
      const email = formData.get('email');
      const subject = formData.get('subject');
      const phone = formData.get('phone');
      const message = formData.get('message');
  
      // Create mailto link
      const mailtoLink = `mailto:support@justdoitservices.com?subject=${encodeURIComponent(subject)}&body=Name:%20${encodeURIComponent(name)}%0AEmail:%20${encodeURIComponent(email)}%0APhone:%20${encodeURIComponent(phone)}%0AMessage:%20${encodeURIComponent(message)}`;
  
      // Open the mailto link (opens default email client)
      window.location.href = mailtoLink;
    };
    
  return (
    
    <>
        <HeaderOne></HeaderOne>
        <section className="contact__page p_relative see__pad">
            <div className="auto-container">
                <div className="row">
                    <div className="col-lg-4 col-md-6 col-ms-12">
                        <div className="contact__left">
                            <figure>
                            <img src={contact} alt="" style={{paddingTop: '70px', objectFit:'cover', width: '100%', height: 'auto', filter: 'brightness(50%) contrast(150%)', borderRadius: '50px'}} />
                            </figure>
                            <h4>Contact Information</h4>
                            <div className="contact__info">
                                <div className="contact__block">
                                    <div className="icon">
                                        <i className="icon-19"></i>
                                    </div>
                                    <div className="contact__text">
                                        <Link to="tell:3363605277" style={{color: 'white', fontSize:'xx-large'}}>(336)360-5277</Link>
                                    </div>
                                </div>
                                <div className="contact__block">
                                    <div className="icon two">
                                        <i className="icon-20"></i>
                                    </div>
                                    <div className="contact__text">
                                        <Link to="mailto:support@justdoitservices.com" style={{color: 'white', fontSize:'x-large'}}>support@justdoitservices.com</Link> 
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-8 col-md-12">
                        <div className="contact__right">
                            <div className="form__title">
                                <div className="title__data">
                                    <div className="sub__title">
                                        <h4>Contact Us</h4>
                                    </div>
                                    <div className="title">
                                        <h2>Get a Free Quote</h2>
                                    </div>
                                </div>
                            </div>
                            <div className="form-inner">
                                <form onSubmit={handleSubmitOne} method="post" action={contactEmail} className="default-form"> 
                                    <div className="row clearfix">
                                        <div className="col-xl-6 form-group">
                                            <input type="name" name="name" placeholder="Your Name *" required="" style={{color: 'white', fontSize:'large'}}/>
                                        </div>
                                        <div className="col-xl-6 col-lg-12 form-group">
                                            <input type="email" name="email" placeholder="Your Email *" required="" style={{color: 'white', fontSize:'large'}}/>
                                        </div>
                                        <div className="col-xl-6 col-lg-12 form-group">
                                            <input type="text" name="subject" placeholder="Your Subject " required=""style={{color: 'white', fontSize:'large'}} />
                                        </div>
                                        <div className="col-xl-6 col-lg-12 form-group">
                                            <input type="text" name="phone" placeholder="Your Phone " required="" style={{color: 'white', fontSize:'large'}}/>
                                        </div>
                                        <div className="ccol-xl-6 col-lg-12 form-group">
                                            <textarea name="message" placeholder="Message" style={{color: 'white', fontSize:'large'}}></textarea>
                                        </div>
                                        <div className="col-xl-12 form-group message-btn btn-box">
                                            <button className="theme-btn theme-btn-one" type="submit" name="submit-form" style={{color: 'white', fontSize:'large'}}>Send your Message</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <div className="google__map">
            
        </div>
        <FooterOne></FooterOne>
    </>
  );
};

export default Contact;
