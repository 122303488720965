import React, { useEffect } from 'react';
import HeaderTwo from '../Header/HeaderTwo';
import FooterTwo from '../Footer/FooterThree';
import Banner from '../Banner/Banner';
import About from '../About/About';
import Service from '../Services/SingleService';
import Choose from '../Choose/Choose';
import Brand from '../Brand/Brand';
import Pricing from '../Pricing/Pricing';
import { pageTitle } from '../PageTitle';
import Process from '../Choose/Process'
import './HomeTwo.css'; // Importing the CSS file

const HomeOne = () => {
    useEffect(() => {
        const sections = document.querySelectorAll('.section');
    
        const observer = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('visible');
                    observer.unobserve(entry.target);
                }
            });
        }, {
            threshold: 0.1 // Adjust the threshold for better triggering on smaller screens
        });
    
        sections.forEach(section => {
            observer.observe(section);
        });
    
        return () => {
            sections.forEach(section => {
                observer.unobserve(section);
            });
        };
    }, []);
    

    document.title = "JDIT Home";
    return (
        <div className="home__two">
            <div className="header-banner">
                <HeaderTwo></HeaderTwo>
                <Banner></Banner>
            </div>
            <div className="section">
                <About></About>
            </div>
            <div className="section">
                <Service></Service>
            </div>
            <div className="section">
                <Choose></Choose>
            </div>
            <div className="section">
                <Process></Process>
            </div> 
            <FooterTwo></FooterTwo>
        </div>
    );
};

export default HomeOne;
